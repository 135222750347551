import React from "react"
import Layout from "../components/layout/Layout.js"
import { Row } from "../components/row/Row"
import { Column50 } from "../components/column/Column.js"
import {
  Heading,
  Title,
} from "../components/title/Title.js"
import { AuthorCard } from '../components/authorCard/AuthorCard.js'
import { TextMedium } from "../components/text/Text.js"
import { Section } from "../components/section/Section.js"
import featuredImage from "../static/undraw_city_driver_jh2h.png"
import Seo from "../components/seo"
import team1 from '../static/authors/andreeamaco.jpg'

function About() {
  return (
    <Layout>
      <Seo title="About School of Content"
       description="My approach starts from the bigger picture and is designed to help enterprise SaaS companies drive demand and revenue 
       through content. I work with startups and scale-ups, but I've helped clients who were already established players in their 
       industries as well."
       image={featuredImage}
       featuredImage={featuredImage} 
       />
      <Section>
        <Title isPrimary title="About me" isCentered />
        <Row isCentered>
          <Column50 isCentered>
            <AuthorCard
              authorName="Andreea Macoveiciuc"
              authorTitle="Marketing Strategist"
              imgSrc={team1}
              hrefLinkedIn="https://www.linkedin.com/in/andreeamacoveiciuc/"
              hrefMedium="https://medium.com/@andreea.macoveiciuc/"
              hrefTwitter="https://twitter.com/Andreea_Maco/"
              hrefGrowthMentor="https://app.growthmentor.com/mentors/andreea-macoveiciuc"
            />
          </Column50>
          <Column50 isFloating>
            <Heading heading="Hey there, I'm Andreea. Nice to meet you!" />
            <TextMedium
              textMedium="I started this project in 2020, out of love for content marketing. Since then, I've worked on 22 content 
              strategy projects, offered 550 hours of training and 1:1 mentoring, and helped over 1.500 marketers bring clarity to 
              their chaotic content landscapes through free resources - e-books, frameworks, and templates."
            />
            <TextMedium
              textMedium="My approach starts from the bigger picture and is designed to help SaaS companies drive demand and revenue 
              through content. I work with startups and scale-ups, but I've helped clients who were already established players in their 
              industries as well."
            />
            <TextMedium
              textMedium="I'd be happy to help you too! If you're not sure where to start, send me an email or book a free call. In my 1:1 calls, 
              I start from your pain points and challenges, and dissect your brand and marketing strategy to identify areas of misalignment and 
              understand what your content is missing. From there, we work together on building a strategy that makes sense for your company's growth stage, 
              goals, and resources."
            />
            <TextMedium textMedium="In the meantime, feel free to connect!" />
          </Column50>
        </Row>
      </Section>
    </Layout>
  )
}

export default About
