import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  ImageContainerSmallest,
  ImageSmall,
} from "../../components/image/Image.js"
import {
  Heading,
} from "../../components/title/Title.js"
import { Link } from "../../components/link/Link.js"
import { StyledDetailsWrapper, StyledAuthorTitle, AuthorWrapper } from "./style.js"
import { colors } from "../../styles/colors.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faMedium,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"

library.add(faMedium, faLinkedin, faTwitter)

export const DetailsWrapper = ({ children, ...props }) => (
  <StyledDetailsWrapper isCentered={props.isCentered}>
    {children}
  </StyledDetailsWrapper>
)

export const AuthorTitle = ({ ...props }) => (
  <StyledAuthorTitle isCentered={props.isCentered}>
    {props.authorTitle}
  </StyledAuthorTitle>
)

export const AuthorCard = ({ ...props }) => {
  return (
    <AuthorWrapper>
      <ImageContainerSmallest>
        <ImageSmall src={props.imgSrc} alt="" />
      </ImageContainerSmallest>
      <Heading isCentered heading={props.authorName} />
      <AuthorTitle isCentered authorTitle={props.authorTitle} />
      <DetailsWrapper isCentered>
        <div>
          <FontAwesomeIcon
            icon={faLinkedin}
            size="1x"
            style={{
              padding: "0 0.5rem",
              verticalAlign: "middle",
              margin: "12px 0 16px",
            }}
            color={colors.colorTextMedium}
            />
          <Link href={props.hrefLinkedIn} anchortext="LinkedIn" />
        </div>
        <div>
          <FontAwesomeIcon
            icon={faTwitter}
            size="1x"
            style={{
              padding: "0 0.5rem",
              verticalAlign: "middle",
              margin: "12px 0 16px",
            }}
            color={colors.colorTextMedium}
            />
          <Link href={props.hrefTwitter} anchortext="Twitter" />
        </div>
        <div>
          <FontAwesomeIcon
            icon={faMedium}
            size="1x"
            style={{
              padding: "0 0.5rem",
              verticalAlign: "middle",
              margin: "12px 0 16px",
            }}
            color={colors.colorTextMedium}
            />
          <Link href={props.hrefMedium} anchortext="Medium" />
        </div>
        <div>
          <FontAwesomeIcon
            icon={faGlobe}
            size="1x"
            style={{
              padding: "0 0.5rem",
              verticalAlign: "middle",
              margin: "12px 0 16px",
            }}
            color={colors.colorTextMedium}
            />
          <Link href={props.hrefGrowthMentor} anchortext="GrowthMentor" />
        </div>
      </DetailsWrapper>
    </AuthorWrapper>
  )
}
