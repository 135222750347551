import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes } from "../../styles/sizes"


export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: ${props => (props.isCentered ? "center" : "space-between")};
  align-items: center;
  width: 100%;
`

export const AuthorWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
`

export const StyledAuthorTitle = styled.p`
  font-size: ${sizes.sizeM};
  line-height: ${sizes.sizeL};
  font-weight: 600;
  color: ${colors.colorContentDark};
  display: block;
  margin: ${props => (props.isCentered ? `0 auto 1rem` : `0 0 1rem`)};
  text-align: ${props => (props.isCentered ? `center` : ``)};
`
